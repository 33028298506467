<template>
   <div class="container card">
            <div class="table-wrapper">
               <div class="table-title">
                    <div class="row">
                    <div class="col-sm-12 text-center">
                        <button style="float:left"  @click="back" class="btn btn-success">Back</button>
                        <h2>Product <b>Details</b></h2>
                    </div>
                    
                </div>
               </div>
               <table class="table table-borderless">
              <thead>
                <tr>
                  <td  scope="col"><b>Brand Name</b></td>
                  <td scope="col">{{product.brand_name}}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td ><b>Generic Name</b></td>
                  <td>{{product.generic_name}}</td>
                </tr>
                 <tr>
                  <td ><b>Other Names</b></td>
                  <td>{{product.other_names}}</td>
                </tr>
                <tr>
                  <td ><b>Global Trade Item Number</b></td>
                  <td>{{product.gtin}}</td>
                </tr>
                <tr>
                  <td ><b>Universal Prodct Code</b></td>
                  <td>{{product.upc}}</td>
                </tr>
                <tr>
                  <td ><b>Batch Number</b></td>
                  <td>{{product.batch_number}}  &nbsp; 
                     <a v-if="!product.batch_number" href="" class="btn btn-success btn-sm" data-toggle="modal"  data-target="#exampleModal"><span class="material-icons">
                    Add Batch Number
                  </span></a></td>
                </tr>
                <tr>
                  <td ><b>Manufacturing Countries</b></td>
                  <td>
                        <p v-for="(country, key) in product.manufacturing_countries" :key="key">
                                {{country}}
                      </p>
                  </td>
                </tr>
                <tr>
                  <td><b>Manufacturing Locations</b></td>
                  <td>
                            <p v-for="(location, key) in product.manufacturing_locations" :key="key">
                                {{location}}
                            </p>
                        </td>
                </tr>
                <tr>
                  <td ><b>Health and Safety Registration Number</b></td>
                  <td>
                     <p v-for="(data, key) in product.health_and_safety_registeration_number" :key="key">
                          {{data}}
                      </p>
                  </td>
                </tr>
                <tr>
                  <td ><b>Certification Number</b></td>
                  <td>
                    <p v-for="(data, key) in product.certification_number" :key="key">
                          {{data}}
                      </p>
                  </td>
                </tr>
                <tr>
                  <td ><b>Standard Registration Number</b></td>
                  <td>
                     <p v-for="(data, key) in product.standards_registeration_number" :key="key">
                          {{data}}
                      </p>
                  </td>
                </tr>
                <tr>
                  <td ><b>Raw Materials List</b></td>
                  <td>{{product.raw_materials_list}}</td>
                </tr>
                <tr>
                  <td ><b>Parking Size</b></td>
                  <td>{{product.parking_size}}</td>
                </tr>
                <tr>
                  <td ><b>HS Code</b></td>
                  <td>{{product.hscode ? product.hscode.hs_code : null}}</td>
                </tr>
                <tr>
                  <td ><b>Expiry date</b></td>
                  <td>{{product.expiry_date}} &nbsp;  <a v-if="!product.expiry_date" href="" class="btn btn-success btn-sm" data-toggle="modal"  data-target="#updateDate"><span class="material-icons">
                    Add Expiry Date
                  </span></a></td>
                </tr>
              </tbody>
            </table>
            <button @click="editProduct" class="btn btn-success btn-sm float-right">Edit Product Details</button>
            </div>
          
           <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Batch Number</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                   <div class="form-group">
                     <input type="number" class="form-control" v-model="batch_number" required>
                   </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button @click="addBatchNumber" type="submit" :disabled="!batch_number" class="btn btn-success" data-dismiss="modal">Add Batch Number</button>
                </div>
                </div>
            </div>
            </div>

           <!--Date  Modal -->
            <div class="modal fade" id="updateDate" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Expiry Date</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                   <div class="form-group">
                    
                     <input type="date" class="form-control" v-model="expiry_date">
                   </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button @click="addExpiryDate" type="submit" class="btn btn-success">Add Expiry Date</button>
                </div>
                </div>
            </div>
            </div>
        </div>    
</template>

<script>
import toasterMixin from '../../mixins/toasterMixin.js'

    export default {
       name:'product-detail',
       data() {
         return {
           product: {},
           batch_number:null,
           expiry_date:null
         }
       },
       methods: {
         back() {
           this.$router.replace(`/entity/${this.$route.params.id}/products`)
         },
         editProduct() {
           this.$router.replace(`/entity/${this.$route.params.id}/product/${this.$route.params.productID}/edit`)
         },
         addBatchNumber(){
           const data = {
             entityID:this.$route.params.id,
             productID:this.$route.params.productID,
             batch_number:{
               batch_number:this.batch_number
             }
           }
            this.$store.dispatch('addBatchNumber', data)
              .then(response => {
                if(response) {
                  this.toast({
                    message: 'Batch Number Added',
                    type:'sucsess'
                  })
                  window.location.reload()
                }
              }).catch(err => {
                if(err?.response?.data == undefined) {
                  this.toast({
                    message:"Network Error.Check Your Network and Try Again. ",
                    type:'error'
                  })
                }
              })
         },
         addExpiryDate(){
           const data = {
             entityID:this.$route.params.id,
             productID:this.$route.params.productID,
             batch_number:{
               expiry_date:this.expiry_date
             }
           }
            this.$store.dispatch('addExpiryDate', data)
              .then(response => {
                if(response) {
                  this.toast({
                    message: 'Expiry Date Added',
                    type:'success'
                  })
                  window.location.reload()
                }
              }).catch(err => {
                if(err?.response?.data == undefined) {
                  this.toast({
                    message:"Network Error.Check Your Network and Try Again. ",
                    type:'error'
                  })
                }
              })
         },
       },
       created() {
         this.$store.dispatch('fetchUserProducts',this.$route.params.id)
          .then(response => {
            const products = response.data.products
            const product = products.find( product => product.id == this.$route.params.productID)
            this.product = product
            console.log(product)
          })
       }
    }
</script>

<style  scoped>
.table-wrapper{
    background: #fff;
    padding: 20px 25px;
    margin: 30px 0;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title{
    padding-bottom: 15px;
    background: linear-gradient(40deg,#ff2020, #05ffa3);
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 1px 1px 0 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title h2{
    margin: 5px 0 0;
    font-size: 24px;
    display: inline;
}


</style>